<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="contact-setting-wrapper">

          <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
            <h4 class="">SMS template list</h4>
          </div>
          <div class="accordion" id="accordionContact">

            <div class="card">
              <div class="card-header" id="settingSmsTemplateListHeading">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#settingContactRequestForListCollapse" aria-expanded="true"
                        aria-controls="collapseContactRequestForList">
                  <span class="text-capitalize contact-title">Default SMS template List</span>
                </button>
              </div>
              <div id="settingContactRequestForListCollapse" class="collapse"
                   aria-labelledby="settingSmsTemplateListHeading" data-parent="#accordionContact">
                <div class="card-body">
                  <SettingSmsTemplateList v-if="defaultSmsTemplateList?.value?.length > 0"
                                          :default-sms-template-list="defaultSmsTemplateList"/>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </template>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/backEnd/AppLayout";
import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import SettingSmsTemplateList
  from "@/views/backEnd/settings/default/smsShortTemplate/includes/SettingSmsTemplateList.vue";

export default {
  name: "SettingLDefaultSmsShortTemplateList.vue",
  mixins: [ShowToastMessage, Loader],
  components: {
    AppLayout,
    SettingSmsTemplateList,
  },
  data() {
    return {
      getSettingsParams: {
        type: ["default"],
        key: [
          'default_sms_template_list',
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      defaultSmsTemplateList: 'appSettings/settingDefaultSmsTemplateList',
    }),
  },

  methods: {
    ...mapActions({
      getSettings: "appSettings/getSettings",
    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.loader(false);
  },

}
</script>

<style>
.contact-wrapper .accordion .card {
  overflow: visible !important;
}

.contact-title {
  font-size: 16px;
  color: rgb(44, 44, 44);
}
</style>
<template>
  <div>
    <div class="px-2">

      <div class="row">

        <div class="w-100 mr-1">
          <div class="d-flex justify-content-end pb-2">
            <button class="btn btn-primary ml-2" @click="addNew()">Add <i class="bx bx-list-plus"></i></button>

          </div>
        </div>

        <div v-for="(smsTemplateList,index) in smsTemplateList" :key="index" class="d-flex w-100 align-items-end mb-1">

          <div class="w-100 mr-1">
            <input type="text" v-model="smsTemplateList.value" class="form-control"
                   :id="`smsTemplateListForList-${index}`"
                   placeholder="SMS short template">

          </div>
          <div>
            <button @click="removeRequest(smsTemplateList.id)" class="btn btn-sm btn-outline-danger"><i
                class='bx bxs-trash'></i></button>
          </div>

        </div>

        <div class="d-flex w-100 align-items-center justify-content-end px-2">
          <div>
            <button @click="updateSettingsHandler" class="btn btn-primary px-lg-2" type="submit">Update</button>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";


export default {
  name: "SettingSmsTemplateList",
  mixins: [ShowToastMessage, Loader, Authorization],
  props: ['defaultSmsTemplateList'],
  data() {
    return {
      smsTemplateList: [],
    };
  },
  computed: {
    ...mapGetters({
      // settingContactRequestForList: 'appSettings/settingContactRequestForList',
    }),


  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),

    async updateSettingsHandler() {
      await this.updateSettings();
    },

    addNew() {
      this.smsTemplateList.push({
        id: this.smsTemplateList.length > 0 ? (this.smsTemplateList[this.smsTemplateList.length - 1].id + 1) : 1,
        value: "",
      });

    },

    removeRequest(smsTemplateListId) {
      if (this.smsTemplateList.length === 1) {
        this.showToastMessage({
          type: 'error',
          message: `Can't delete`
        });
        return;
      }
      this.smsTemplateList = this.smsTemplateList.filter((smsTemplateList) => smsTemplateList.id !== smsTemplateListId);
    },

    async updateSettings() {
      const isAllContactRequestValueIsFilledUp = this.smsTemplateList.every((item) => {
        return !!item.value;
      })
      if (!isAllContactRequestValueIsFilledUp) {
        const toastObj = {
          message: 'Please insert value for newly added items ',
          type: 'error'
        };

        this.showToastMessage(toastObj);
        return;
      }
      let dataObj = {
        id: this.defaultSmsTemplateList.id,
        data: {
          type: this.defaultSmsTemplateList.type,
          key: this.defaultSmsTemplateList.key,
          value: this.smsTemplateList.map(item => item.value)
        }
      };
      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          this.showToastMessage({
            message: 'SMS template list updated.',
            type: 'success'
          });
        }
        this.showToastMessage(response);
      });
    }
  },
  async mounted() {
    this.smsTemplateList = (this.defaultSmsTemplateList?.value ?? []).map((item, index) => ({value: item, id: index}))
  }
}
</script>

<style scoped>

</style>